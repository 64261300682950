import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ensureCurrentUser, checkMarketplaceCurrentUser } from '../../util/data';
import { string, func, bool } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { priceData } from '../../util/currency';
import { ensureListing } from '../../util/data';
import config from '../../config';
import { somePeriodAgo } from '../../util/dates';
import { queryRiderListing } from '../../ducks/riderListings.duck';
import {
    GalleryCarouselWrapper,
    FavoriteIcon,
    AvailabilityBadge,
    Button,
    Tooltip,
} from '../../components';
import LocationIcon from './LocationIcon';
import DotIcon from './DotIcon';
import css from './ListingCard.css';
import {
    ClockIconTiny,
    HeartIcon,
    ImagePlaceholderIcon,
    VideoPlaceholderIcon,
    SuccessIcon,
    UpdateCircleIcon,
    CheckmarkIcon,
} from '../../icons';
import { SQUARE_SMALL_2X } from '../../ducks/Assets.duck';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useAssets } from '../../hooks/useAssets';
import { getUserExternalReview } from '../../containers/ProfilePage/ProfilePage.duck';
import LikeIcon from '../../forms/ProfileSettingsForm/icons/LikeIcon';
import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import { formatDate, getCardTitles, handleListingCardClick, resolveInfoItemsData } from './helpers';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { getListingsById } from '../../ducks/marketplaceData.duck';

const { userTypeRider, userTypeHorseowner } = config;

const MODAL_BREAKPOINT = 1024;
const NEW_LISTING_NUMBER_OF_DAYS = 2;

const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

const panelMediumWidth = 50;
const panelLargeWidth = 62.5;

const defaultRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
].join(', ');

const findListingForRider = ({
    attributes: {
        publicData: { userRepresentationId },
    },
}) => !!userRepresentationId;

const findListingForOwner = mainHorseId => ({ id: { uuid } }) =>
    mainHorseId && uuid && uuid === mainHorseId;

const shouldRenderImageItem = item =>
    item &&
    item.attributes &&
    item.attributes.variants &&
    (
        item.attributes.variants['landscape-crop2x'] ||
        item.attributes.variants['landscape-crop'] ||
        item.attributes.variants['square-small2x'] ||
        item.attributes.variants['square-small'] || { url: null }
    ).url;

export const ListingCard = props => {
    const {
        className,
        rootClassName,
        cardWrapperClassName,
        listingRefClassName,
        intl,
        listing,
        renderSizes,
        setActiveListing = () => null,
        genders,
        /**
         * postpone e.g. images loading
         */
        postponed = {},
        noFavorite,
        favoriteComponent = null,
        currentUser,
        currentUserMainListing,
        assetsLoadingRequests,
        recommendationsNum,
        onGetUserExternalReview,
        identitySectionClassName,
        notifyOnScoreChange = () => null,
        handleListingCardClick: handleListingCardClickProp,
        onQueryRiderListing,
        matchingScoreListingId,
        listingAuthorId,
        currentUserDefined,
        isRider,
    } = props;
    const [slideIndex, setSlideIndex] = useState();
    const {
        assets: assetsPostponed,
        riderListing: riderListingPostponed,
        matching: matchingPostponed,
    } = postponed;

    const loading = assetsPostponed || assetsLoadingRequests;

    const paragraphEl = useRef(null);
    const cardRef = useRef(null);

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureListing(listing);

    const { distanceFromSelectedPlace } = currentListing;
    const { attributes, author } = currentListing;
    const { price, publicData, createdAt } = attributes;
    // const { profileImage: authorProfileImage } = author || {};

    const {
        gender,
        publicAddress,
        userRepresentationId,
        hasVideo,
        updatedAt,
        openForPartTimeProposals,
        availability: user_availability,
        availabilityStatus: user_availabilityStatus,
    } = publicData;

    const past = somePeriodAgo(NEW_LISTING_NUMBER_OF_DAYS, 'day');
    const createdAtFormatted = createdAt && formatDate(new Date(createdAt));
    const updatedAtFormatted = updatedAt && formatDate(new Date(updatedAt));
    const listingPublicAddress = publicAddress ? publicAddress.trim() : publicAddress;
    const isUserListing = !!userRepresentationId;
    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;
    const isTemporaryListing = !isUserListing && openForPartTimeProposals === 'temporary';
    const isNewListing = !isUserListing && createdAt && past < createdAt;
    const currentUserId = currentUserDefined ? currentUser.id.uuid : null;
    const currentListingId = currentListing && currentListing.id ? currentListing.id.uuid : null;

    const { formattedPrice, priceTitle } = isUserListing ? {} : priceData(price, intl);
    const { title } = currentListing.attributes || '';

    const cardTitles = getCardTitles(title, genders, gender);
    const riderTitle = isUserListing ? (title || '').split(' ')[0] : title;

    const isSameUser =
        currentUserDefined && listingAuthorId && currentUserId === listingAuthorId
            ? `sameUser-${isUserListing ? 'rider' : 'horse'}`
            : null;
    const isHorseOwner =
        currentUserDefined &&
        currentUser.attributes.profile.publicData.userType === userTypeHorseowner;
    const noListingsFavorForOwners =
        isHorseOwner && !isUserListing ? 'noListingsFavorForOwners' : null;

    const unitTranslationKey = isNightly
        ? 'ListingCard.perNight'
        : isDaily
        ? 'ListingCard.perDay'
        : 'ListingCard.perUnit';

    const locationHolderMaybe =
        listingPublicAddress ||
        distanceFromSelectedPlace ||
        isTemporaryListing ||
        recommendationsNum;

    const syntheticUser = isUserListing
        ? {
              ...(author || {}),
              attributes: {
                  ...(author ? author.attributes : {}),
                  profile: {
                      ...(author && author.attributes ? author.attributes.profile : {}),
                      publicData: {
                          ...(author && author.attributes
                              ? author.attributes.profile.publicData
                              : {}),
                          availability: user_availability,
                          availabilityStatus: user_availabilityStatus,
                      },
                  },
              },
          }
        : null;

    const syntheticRiderListing = isUserListing
        ? {
              id: {
                  uuid: userRepresentationId,
              },
              attributes: {
                  publicData: {
                      userRepresentationId,
                  },
              },
              author: author || {},
          }
        : null;
    /**
     * a horseowner looks on a horse listing;
     * not allowed, edge case is handled
     */
    const ownersView = isHorseOwner && !isUserListing;
    /**
     * a rider looks on a rider-listing;
     * not allowed, edge case is handled
     */
    const ridersView = isRider && isUserListing;
    const authorId = Boolean(isSameUser || ridersView || ownersView) ? null : currentUserId;
    /* useEffect */
    const riderListingRequestAllowed = !ridersView && !ownersView && currentUserDefined;
    useEffect(() => {
        if (!riderListingRequestAllowed || riderListingPostponed) return;

        onQueryRiderListing(authorId);
    }, [riderListingPostponed, authorId, riderListingRequestAllowed]);

    /**
     * no matching
     * - when rider searches for other riders
     * - when owner searches for other horses
     */
    const riderViewsRider = isRider && isUserListing;
    const matchingAllowed = Boolean(
        !matchingPostponed && !riderViewsRider && !ownersView && currentUserDefined
    );

    const riderMatchingParams = {
        riderListingId: matchingAllowed ? matchingScoreListingId : null,
        horseOwnerListingId: currentListingId,
    };

    const ownerMatchingParams = {
        riderListingId: matchingAllowed ? currentListingId : null,
        horseOwnerListingId: matchingScoreListingId,
    };

    /* useEffect */
    const [matchingMatrixLoading, matchingScore] = useMatchingMatrix(
        isRider ? riderMatchingParams : ownerMatchingParams
    );

    const listingToRequestAssets = isUserListing ? syntheticRiderListing : currentListing;

    /* useEffect */
    const images = useAssets({
        listing: assetsPostponed ? { id: { uuid: null } } : listingToRequestAssets,
        allowedTypes: ['image', 'video'],
        variant: SQUARE_SMALL_2X,
    });

    useEffect(() => {
        if (isUserListing) {
            onGetUserExternalReview(userRepresentationId);
        }
    }, [isUserListing]);

    const isLastSlideItem = slideIndex === images.length;

    const { finalScore, scorePalette } = matchingScore || {};
    const matchingMatrixAllowed = currentUserDefined && currentListingId && matchingScoreListingId;

    useEffect(() => {
        scorePalette && notifyOnScoreChange(scorePalette);
    }, [scorePalette]);

    const favoriteDisabled = Boolean(isSameUser || noListingsFavorForOwners);

    const defaultFavoriteComponent = noFavorite ? null : (
        <FavoriteIcon
            rootClassName={css.favoriteIcon}
            currentListing={currentListing}
            disabled={favoriteDisabled}
        >
            {favoriteDisabled ? (
                <Tooltip
                    rootClassName={css.tooltip}
                    tooltipClassName={css.tooltipContent}
                    parentContainerRef={cardRef}
                    content={
                        <>
                            <p>
                                <FormattedMessage
                                    id={`ListingCard.tooltipHeading-${isSameUser ||
                                        noListingsFavorForOwners}`}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={`ListingCard.tooltipDesc-${isSameUser ||
                                        noListingsFavorForOwners}`}
                                    values={{ listingTitle: title }}
                                />
                            </p>
                        </>
                    }
                >
                    <HeartIcon />
                </Tooltip>
            ) : null}
        </FavoriteIcon>
    );

    const aspectWrapperClasses = classNames({
        [css.aspectWrapper]: true,
        [css.aspectWrapperNoBackground]: assetsLoadingRequests,
    });

    const imagesSection =
        images && images.length ? (
            <GalleryCarouselWrapper
                dragEnabled
                pagination
                touchEnabled={isMobileLayout}
                items={images}
                renderSizes={renderSizes}
                notifyOnSlideChange={slideIndex => setSlideIndex(slideIndex)}
                rootClassName={css.galleryCarouselWrapper}
                actionItem={{
                    type: 'actionItem',
                    content: (
                        <div className={css.actionItem}>
                            <Button>
                                <FormattedMessage
                                    id={`ListingCard.actionItem-${
                                        isUserListing ? 'rider' : 'horse'
                                    }`}
                                />
                            </Button>
                        </div>
                    ),
                }}
                renderCarouselItem={item => {
                    const itemVariant = shouldRenderImageItem(item);

                    if (!itemVariant) return null;

                    return (
                        <div
                            className={css.rootForImage}
                            style={{
                                backgroundImage: `url(${itemVariant})`,
                                // backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbyF4pWXHeZEQ4RRLEoyDMCzon4Z3g-lXInA&s)`,
                            }}
                        />
                    );
                }}
            >
                {!isLastSlideItem && <code className={css.overlayBottom} />}
            </GalleryCarouselWrapper>
        ) : null;

    const { discipline, disciplineLevel, ridings } = resolveInfoItemsData({
        currentListing,
        currentUser,
        currentUserMainListing,
    });

    const infoItems = [discipline, ridings, disciplineLevel]
        .filter(i => !!i && i.content)
        .map(({ content, matching }, index) => {
            const noMatchForRiders =
                currentUserDefined &&
                currentUser.attributes.profile.publicData.userType === userTypeRider &&
                isUserListing;

            const matchForRidersUnavailable =
                !isUserListing &&
                isRider &&
                currentUser.attributes.profile.publicData.availabilityStatus ===
                    RIDER_AVAILABILITY_NOT_AVAILABLE;

            const listingMatches = !noMatchForRiders && !matchForRidersUnavailable && matching;

            return (
                <div
                    key={index}
                    className={classNames({
                        [css.infoItem]: true,
                        [css.infoItemMatches]: listingMatches,
                    })}
                >
                    {listingMatches && <CheckmarkIcon />}
                    {content}
                </div>
            );
        });

    const infoLineMaybe = infoItems.length > 0;

    const additionalInfoSection = discipline &&
        discipline.content &&
        disciplineLevel &&
        disciplineLevel.content && (
            <div className={css.additionalInfoItem}>
                <FormattedMessage id="ListingCard.additionalInfo" />
            </div>
        );

    return (
        <div className={classes} id={`ListingCard-${currentListing.id.uuid}`}>
            {favoriteComponent || defaultFavoriteComponent}

            {isNewListing && (
                <div className={css.newListingNotification}>
                    <FormattedMessage id="ListingCard.newListing" />
                </div>
            )}

            <div
                className={classNames(css.cardWrapper, {
                    [cardWrapperClassName]: !!cardWrapperClassName,
                })}
                onClick={e =>
                    typeof handleListingCardClickProp === 'function'
                        ? handleListingCardClickProp(e)
                        : handleListingCardClick(e, {
                              history: props.history,
                              currentListing,
                              isUserListing,
                              userRepresentationId,
                          })
                }
                onMouseEnter={() => setActiveListing(currentListing.id)}
                onMouseLeave={() => setActiveListing(null)}
            >
                <main
                    className={classNames(css.listingRef, {
                        [listingRefClassName]: !!listingRefClassName,
                    })}
                    ref={cardRef}
                >
                    <section className={css.threeToTwoWrapper}>
                        {!isLastSlideItem && (
                            <section
                                className={classNames(css.identitySection, {
                                    [identitySectionClassName]: !!identitySectionClassName,
                                })}
                            >
                                <h4 className={css.title} ref={paragraphEl}>
                                    {isUserListing ? riderTitle : cardTitles}
                                </h4>
                                {locationHolderMaybe && (
                                    <div className={css.locationHolder}>
                                        {listingPublicAddress && (
                                            <>
                                                <LocationIcon />
                                                <span>{listingPublicAddress}</span>
                                            </>
                                        )}
                                        {!!distanceFromSelectedPlace && (
                                            <div className={css.locationInfo}>
                                                <DotIcon />
                                                <span className={css.locationDisctance}>
                                                    {distanceFromSelectedPlace} km
                                                </span>
                                            </div>
                                        )}
                                        {isTemporaryListing && (
                                            <div className={css.tempListing}>
                                                <ClockIconTiny />
                                                <FormattedMessage id="ListingCard.tempListing" />
                                            </div>
                                        )}
                                        {recommendationsNum && isUserListing && (
                                            <p className={css.recommendationsNumSection}>
                                                <LikeIcon /> <span>{recommendationsNum} </span>
                                                <FormattedMessage id="ProfileSettingsPage.recommendations" />
                                            </p>
                                        )}
                                    </div>
                                )}
                            </section>
                        )}
                        <div className={aspectWrapperClasses}>
                            {loading ? (
                                <div className={css.loadingOverlay}>
                                    {hasVideo ? <VideoPlaceholderIcon /> : <ImagePlaceholderIcon />}
                                </div>
                            ) : (
                                imagesSection
                            )}
                        </div>
                    </section>

                    {currentUserId && matchingMatrixAllowed && (
                        <code
                            className={classNames({
                                [css.progressBar]: true,
                                [css[scorePalette]]: !!scorePalette,
                            })}
                        >
                            <mark style={{ width: `${finalScore || 0}%` }} />
                        </code>
                    )}

                    <section className={css.listingInfoSection}>
                        {matchingMatrixLoading && <code className={css.mmLoadingIndicator} />}
                        {!matchingMatrixLoading &&
                            typeof finalScore === 'number' &&
                            matchingMatrixAllowed && (
                                <p className={css.matchingScore}>
                                    {finalScore}% <FormattedMessage id="ListingCard.finalScore" />
                                </p>
                            )}
                        {infoLineMaybe && (
                            <>
                                <div className={css.infoLine}>
                                    {infoItems.map(i => i)}
                                    {additionalInfoSection}
                                </div>
                                {!isUserListing && <div className={css.divider} />}
                            </>
                        )}
                        {!isUserListing && (
                            <aside className={css.horseListingSection}>
                                {createdAtFormatted && (
                                    <div className={css.listingCreationInfo}>
                                        {updatedAtFormatted ? (
                                            <UpdateCircleIcon />
                                        ) : (
                                            <SuccessIcon rootClassName={css.createdAtIcon} />
                                        )}
                                        {updatedAtFormatted || createdAtFormatted}
                                    </div>
                                )}
                                {formattedPrice && (
                                    <div className={css.price}>
                                        <div
                                            className={css.priceValue}
                                            title={priceTitle && priceTitle}
                                        >
                                            {formattedPrice}
                                        </div>
                                        <div className={css.perUnit}>
                                            <FormattedMessage id={unitTranslationKey} />
                                        </div>
                                    </div>
                                )}
                            </aside>
                        )}
                    </section>
                    {isUserListing && syntheticUser && (
                        <div className={css.availabilityBadge}>
                            <AvailabilityBadge
                                user={syntheticUser}
                                editAllowed={false}
                                parentContainerRef={cardRef}
                                helpTextAllowed
                                isPublic
                            />
                        </div>
                    )}
                </main>
            </div>
        </div>
    );
};

ListingCard.defaultProps = {
    className: null,
    rootClassName: null,
    renderSizes: defaultRenderSizes,
    setActiveListing: () => null,

    genders: config.custom.genders,
    noFavorite: false,
};

ListingCard.propTypes = {
    className: string,
    rootClassName: string,
    intl: intlShape.isRequired,
    listing: propTypes.listing.isRequired,
    noFavorite: bool,
    // Responsive image sizes hint
    renderSizes: string,
    setActiveListing: func,
};

const mapStateToProps = (state, ownProps) => {
    const { listing } = ownProps;
    const { id, author, attributes } = listing || {};
    const { uuid: listingId } = id || { uuid: null };
    const {
        publicData: { userRepresentationId },
    } = attributes || { publicData: { userRepresentationId: null } };
    const currentUser = ensureCurrentUser(checkMarketplaceCurrentUser(state));
    const { Assets, ProfilePage, user, riderListings } = state;
    const { currentUserMainListing } = user;
    const { assetsLoadingRequests } = Assets;
    const { externalReviewsRequests, externalReviewsData } = ProfilePage;
    const { riderListings: listings } = riderListings;

    const userListingsDataAvailable = Object.values(listings || {})[0];
    const currentUserDefined = currentUser && currentUser.id;
    const userListings = userListingsDataAvailable
        ? getListingsById(state, Object.values(userListingsDataAvailable))
        : null;

    const {
        attributes: {
            profile: {
                publicData: { mainHorseId },
            },
        },
    } = currentUser;
    const isRider =
        currentUserDefined && currentUser.attributes.profile.publicData.userType === userTypeRider;

    const listingAuthorId = author && author.id && author.id.uuid;
    /**
     * listing representation for rider;
     * main horse for owner;
     */
    const matchingScoreListing = Array.isArray(userListings)
        ? userListings.find(isRider ? findListingForRider : findListingForOwner(mainHorseId))
        : null;

    const matchingScoreListingId =
        matchingScoreListing && matchingScoreListing.id ? matchingScoreListing.id.uuid : null;

    const externalReviews = externalReviewsData && externalReviewsData[listingAuthorId];

    const externalReviewsRequest =
        externalReviewsRequests && externalReviewsRequests[listingAuthorId];

    const externalReviewsDataAvailable =
        !externalReviewsRequest && Array.isArray(externalReviews) && externalReviews.length > 0
            ? externalReviews
            : null;

    const recommendationsApproved = externalReviewsDataAvailable
        ? externalReviewsDataAvailable.filter(({ status }) => status === 'approved').length
        : null;

    return {
        currentUser,
        currentUserMainListing,
        assetsLoadingRequests: Boolean(
            assetsLoadingRequests[listingId] || assetsLoadingRequests[userRepresentationId]
        ),
        externalReviews,
        matchingScoreListingId,
        currentUserDefined,
        listingAuthorId,
        isRider,
        recommendationsNum: recommendationsApproved,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetUserExternalReview: userReviewedId => dispatch(getUserExternalReview(userReviewedId)),
        onQueryRiderListing: authorId => dispatch(queryRiderListing(authorId)),
        dispatch,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(ListingCard);
